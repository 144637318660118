export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34')
];

export const server_loads = [2];

export const dictionary = {
		"/(app)": [8,[2]],
		"/(app)/exports": [23,[2]],
		"/login": [33,[7]],
		"/login/password-reset/[tenant]/[email]": [34,[7]],
		"/(app)/(settings)/management": [9,[2,3]],
		"/(app)/(settings)/management/groups": [10,[2,3]],
		"/(app)/(settings)/management/groups/create": [11,[2,3]],
		"/(app)/(settings)/management/groups/edit/[name]": [12,[2,3]],
		"/(app)/(settings)/management/users": [13,[2,3]],
		"/(app)/(settings)/management/users/[username]/permissions": [14,[2,3]],
		"/(app)/(settings)/management/views": [15,[2,3,4]],
		"/(app)/(settings)/management/views/create": [16,[2,3,4]],
		"/(app)/(settings)/management/views/edit/[name]": [17,[2,3,4]],
		"/(app)/masquerade": [24,[2]],
		"/(app)/push": [25,[2,5]],
		"/(app)/push/upload": [26,[2,5]],
		"/(app)/push/upload/media": [27,[2]],
		"/(app)/push/upload/tag-sheet": [28,[2]],
		"/(app)/reports": [29,[2]],
		"/(app)/screenshots/[view]/view2/[[filter=screenshots_filter]]/[...slug=structure_ids]": [32,[2,6]],
		"/(app)/screenshots/[view]/view/[[filter=screenshots_filter]]/[[groupBy=group_by]]/[...slug=structure_ids]": [31,[2,6]],
		"/(app)/screenshots/[view]/[[filter=screenshots_filter]]/[...slug=structure_id_parents]": [30,[2,6]],
		"/(app)/(settings)/settings": [18,[2,3]],
		"/(app)/(settings)/settings/change-password": [19,[2,3]],
		"/(app)/(settings)/settings/filters": [20,[2,3]],
		"/(app)/(settings)/settings/interface": [21,[2,3]],
		"/(app)/(settings)/settings/profile": [22,[2,3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';